import React from "react";
import { t } from "@lingui/macro";
import { CellContext } from "@tanstack/react-table";

import { UserAvatar } from "_/components/avatar";
import { Button, ButtonGroup } from "_/components/button";
import { DateTime } from "_/components/date-time";
import { ColumnDef, Table } from "_/components/table";
import { useToasts } from "_/components/toasts";

import { Invitation, useInvites, useInviteMutations } from "_/data/invitations";
import { Role, translateRoleName } from "_/data/rbac";
import { useUsers } from "_/data/users";

import { Uuid } from "_/types";
import { indexById } from "_/utils";

import * as S from "./styled";

/**
 * Invitation list component for use in the admin panel for an organization.
 */
export const InvitationsList = ({ orgId }: { orgId: Uuid }) => {
  const { data: invitations = [] } = useInvites(orgId);
  const { resendInvite, revokeInvite, reviewInvite } = useInviteMutations();
  const [_toasts, addToast, _removeToast] = useToasts();
  const { data: users = [] } = useUsers();

  const usersById = indexById(users);

  // Helper to show a success toast.
  const toastSuccess = (title: string, content: string) => {
    addToast({ kind: "success", title, content });
  };

  // Helper for displaying error toast if an API request fails.
  const toastError = (err: { message: string }) => {
    addToast({
      kind: "danger",
      title: "Operation failed",
      content: err.message,
    });

    // Rethrow the error so that the mutation can catch it (if needed) and the
    // success toast is not shown.
    throw err;
  };

  // Render function for displaying a user cell for the reviewed-by and
  // received-by columns.
  const userCell = ({ cell }: CellContext<Invitation, unknown>) => {
    const userId = cell.getValue() as Uuid;

    const user = usersById[userId];

    if (!user) {
      return "--";
    }

    return (
      <S.UserCell>
        <UserAvatar resource={user} size="small" />
        <span>{user.name}</span>
      </S.UserCell>
    );
  };

  // Render function for actions cell of each row.
  const actionsCell = ({
    row: { original },
  }: CellContext<Invitation, unknown>) => {
    const params = { invitationId: original.id };
    const resend = async () => {
      await resendInvite.mutateAsync(params);
      toastSuccess(
        t`components.invitations-list.resent-toast.title`,
        t`components.invitations-list.resent-toast.content ${original.email}`
      );
    };

    const revoke = async () => {
      await revokeInvite.mutateAsync(params).catch(toastError);
      toastSuccess(
        t`components.invitations-list.revoked-toast.title`,
        t`components.invitations-list.revoked-toast.content ${original.email}`
      );
    };

    const approve = async () => {
      await reviewInvite
        .mutateAsync({ ...params, approval: true })
        .catch(toastError);
      toastSuccess(
        t`components.invitations-list.approved-toast.title`,
        t`components.invitations-list.approved-toast.content ${original.email}`
      );
    };

    const deny = async () => {
      await reviewInvite
        .mutateAsync({ ...params, approval: false })
        .catch(toastError);
      toastSuccess(
        t`components.invitations-list.denied-toast.title`,
        t`components.invitations-list.denied-toast.content ${original.email}`
      );
    };

    const buttons = original.approved ? (
      <>
        <Button size="small" kind="secondary" onClick={resend}>
          {t`common.resend`}
        </Button>
        <Button size="small" kind="danger" onClick={revoke}>
          {t`common.revoke`}
        </Button>
      </>
    ) : (
      <>
        <Button size="small" kind="secondary" onClick={approve}>
          {t`common.approve`}
        </Button>
        <Button size="small" kind="secondary" onClick={deny}>
          {t`common.deny`}
        </Button>
      </>
    );

    return <ButtonGroup>{buttons}</ButtonGroup>;
  };

  const columns: ColumnDef<Invitation>[] = [
    {
      id: "email",
      header: t`components.invitations-list.sent-to`,
      accessorKey: "email",
      cell: ({ cell }) => (
        <a target="_blank" href={`mailto:${cell.getValue()}`} rel="noreferrer">
          {cell.getValue<string>()}
        </a>
      ),
    },
    {
      id: "role",
      header: "Role",
      accessorKey: "role",
      cell: ({ cell }) => translateRoleName(cell.getValue<Role>()),
    },
    {
      id: "inviter",
      header: t`components.invitations-list.invited-by`,
      accessorKey: "invitedBy",
      cell: userCell,
    },
    {
      id: "reviewed",
      header: t`components.invitations-list.approved-by`,
      accessorKey: "reviewedBy",
      cell: userCell,
    },
    {
      id: "expiry",
      header: t`common.expiry`,
      accessorKey: "expiresAt",
      cell: ({ cell }) => (
        <DateTime value={cell.getValue<string>()} defaultValue="--" />
      ),
    },
    {
      id: "actions",
      header: t`common.actions`,
      cell: actionsCell,
    },
  ];

  const activeInvites = invitations.filter((i) => i.revokedBy === null);

  return <Table columns={columns} data={activeInvites} />;
};
