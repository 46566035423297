import React, {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { Switch, Route, useLocation, useRoute } from "_/components/router";
import { useAtom } from "jotai";
import { t } from "@lingui/macro";

import { Camera } from "_/components/camera";
import { NavCube } from "_/components/nav-cube";
import { PrepareViewerControlPanel } from "_/components/viewer-controls/prepare";
import { AnalyzeViewerControlPanel } from "_/components/viewer-controls/analyze";
import { OptimizeViewerControlPanel } from "_/components/viewer-controls/optimize";
import { PrintView } from "_/components/view-loaders/analyze";
import { SimView } from "_/components/view-loaders/sim";
import { Bed } from "_/components/bed";
import { useToasts } from "_/components/toasts";
import { Tooltip } from "_/components/tooltip";

import { projectRoutes } from "_/routes";

import { gpuWarningLastAcknowledgedAtom } from "_/state";

import { GCodeView } from "_/components/view-loaders/gcode";

import {
  View,
  useViewerState,
  viewAtom,
  ViewMode,
  glInfoAtom,
  BED_DIMENSIONS,
} from "_/state/viewer";

import { Project, useProjectAttempts } from "_/data/projects";

import * as S from "./styled";

const generateViewObject = (view: View<ViewMode>): ReactElement | null => {
  if (view.mode === ViewMode.Gcode) {
    const gcodeView = view as View<ViewMode.Gcode>;

    return <GCodeView view={gcodeView} key={gcodeView.id} />;
  } else if (view.mode === ViewMode.Print) {
    const printView = view as View<ViewMode.Print>;

    return <PrintView view={printView} key={printView.id} />;
  } else if (view.mode === ViewMode.Sim) {
    const simView = view as View<ViewMode.Sim>;

    return <SimView view={simView} key={simView.id} />;
  }

  return null;
};

const GPUNotificationBody = () => (
  <S.GPUNotificationBody>
    {t`components.viewer.windows-gpu-configuration-message`}
    <S.DocsLink
      href="https://basis.aon3d.com/docs/basis/gpu-configuration"
      target="_blank"
    >
      {t`components.viewer.windows-gpu-configuration-doc`}
    </S.DocsLink>
  </S.GPUNotificationBody>
);

const GPUDetector = ({
  onIGPUDetected,
}: {
  onIGPUDetected?: () => void;
}): ReactElement => {
  const { gl } = useThree();
  const [_toasts, addToast] = useToasts();
  const [gpuWarningLastAcknowledged, setGpuWarningLastAcknowledged] = useAtom(
    gpuWarningLastAcknowledgedAtom
  );

  const [_glInfo, setGLInfo] = useAtom(glInfoAtom);

  const gpuWarningAcknowledgedThisWeek =
    Date.now() - gpuWarningLastAcknowledged < 7 * 24 * 60 * 60 * 1000;

  const hasRun = useRef(false);

  useEffect(() => {
    if (hasRun.current || !gl) {
      return;
    }

    hasRun.current = true;

    const glContext = gl.getContext() as WebGL2RenderingContext;

    const info = glContext.getExtension("WEBGL_debug_renderer_info");

    // Get the maximum texture size supported by the GPU
    // This is used to determine the dimensions of the sensor data array texture
    const maxTextureSize = glContext.getParameter(glContext.MAX_TEXTURE_SIZE);

    setGLInfo({ maxTextureSize });

    if (!info) {
      return;
    }

    const renderer = gl.getContext().getParameter(info.UNMASKED_RENDERER_WEBGL);

    // Check if the browser is running on a Windows machine
    const userAgentRegex = /windows/i;
    const isWindows = userAgentRegex.test(navigator.userAgent);

    // Check if the unmasked renderer string indicates that the renderer is Intel (U)HD
    const rendererRegex = /intel.*u?hd/i;
    const isIntelIntegrated = rendererRegex.test(renderer);

    if (isWindows && isIntelIntegrated) {
      if (!gpuWarningAcknowledgedThisWeek) {
        addToast({
          kind: "warning",
          title: t`components.viewer.windows-gpu-configuration-title`,
          content: <GPUNotificationBody />,
          actions: [
            {
              label: t`common.ok`,
              action: () => {
                setGpuWarningLastAcknowledged(Date.now());
                onIGPUDetected?.();
              },
            },
          ],
        });
      } else {
        onIGPUDetected?.();
      }
    }
  }, [
    gl,
    addToast,
    gpuWarningLastAcknowledged,
    setGpuWarningLastAcknowledged,
    gpuWarningAcknowledgedThisWeek,
    onIGPUDetected,
    setGLInfo,
  ]);

  return <></>;
};

type ViewerCanvasProps = {
  /**
   * Project to view.
   */
  project: Project;

  /**
   * Callback function to be triggered if Windows + Intel (U)HD configuration is detected
   */
  onIGPUDetected?: () => void;
};

/**
 * Canvas / 3d viewer area of the project viewer.
 */

const ViewerCanvas = ({
  project,
  onIGPUDetected,
}: ViewerCanvasProps): ReactElement => {
  const [optActive, _optParams] = useRoute("/(.*)/optimize/(.*)?");

  const [viewerState, setViewerState] = useViewerState(
    project.id,
    optActive ? "optimize" : "prepareAnalyze"
  );

  const [viewState] = useAtom(viewAtom);

  const viewerContents = useMemo(() => {
    if (viewState) {
      return generateViewObject(viewState);
    }
    return null;
  }, [viewState]);

  const baseHeight = 250;

  const width = BED_DIMENSIONS.width;
  const length = BED_DIMENSIONS.height;

  const keyLightIntensity = 412_500;
  const fillLightIntensity = 212_500;
  const backLightIntensity = 150_000;

  return (
    <Canvas frameloop="demand">
      <GPUDetector onIGPUDetected={onIGPUDetected} />
      <Camera viewerState={viewerState} setViewerState={setViewerState} />
      <NavCube />
      <ambientLight intensity={0.75} />
      <pointLight
        position={[-100, width / 4, baseHeight + 100]}
        intensity={keyLightIntensity}
      />
      <pointLight
        position={[width, length / 4, baseHeight + 50]}
        intensity={fillLightIntensity}
      />
      <pointLight
        position={[-50, -50, baseHeight]}
        intensity={backLightIntensity}
      />
      <pointLight
        position={[width + 50, length + 50, baseHeight]}
        intensity={backLightIntensity}
      />
      <pointLight
        position={[width / 4, length + 50, baseHeight]}
        intensity={backLightIntensity}
      />
      {viewerContents}
      {!optActive && <Bed visible={viewerState.cameraPosition[2] >= 0} />}
    </Canvas>
  );
};

type ViewerControlPanelProps = {
  /**
   * Project to view.
   */
  project: Project;
};

/**
 * Control panel area of the project viewer.
 */
const ViewerControlPanel = ({
  project,
}: ViewerControlPanelProps): ReactElement => {
  const { data: attempts } = useProjectAttempts(project.id);

  return (
    <S.ControlPanelContainer>
      <Switch>
        <Route path={projectRoutes.wildcards.prepare}>
          <PrepareViewerControlPanel project={project} />
        </Route>
        <Route path={projectRoutes.wildcards.analyze}>
          <AnalyzeViewerControlPanel
            project={project}
            attempts={attempts ?? []}
          />
        </Route>
        <Route path={projectRoutes.wildcards.optimize}>
          <OptimizeViewerControlPanel project={project} />
        </Route>
      </Switch>
    </S.ControlPanelContainer>
  );
};

/**
 * Warning icon / tooltip to be displayed in the viewer if Windows + Intel (U)HD
 * configuration is detected. When hovered over, a notification message linking to
 * the relevant documentation will be displayed.
 */
const ViewerGPUWarning = () => (
  <S.GPUWarningContainer>
    <Tooltip
      title={t`components.viewer.windows-gpu-configuration-title`}
      placement="rightEnd"
      wait={0}
      delay={0}
      content={<GPUNotificationBody />}
      target={<S.WarningIcon variant="Warning" />}
    />
  </S.GPUWarningContainer>
);

type ViewerProps = {
  /**
   * Project to view.
   */
  project: Project;
};

export const Viewer = ({ project }: ViewerProps): ReactElement => {
  const [location, _setLocation] = useLocation();

  const [_view, setView] = useAtom(viewAtom);

  const [showGPUWarning, setShowGPUWarning] = useState(false);

  useLayoutEffect(() => {
    setView(null);
  }, [location, setView]);

  return (
    <S.Wrapper>
      {showGPUWarning && <ViewerGPUWarning />}
      <ViewerCanvas
        project={project}
        onIGPUDetected={() => {
          setShowGPUWarning(true);
        }}
      />
      <ViewerControlPanel project={project} />
    </S.Wrapper>
  );
};
