/**
 * Invitation data fetching and manipulation hooks.
 *
 * Both `invitation` and `invite` are used here, but `invitation` should be
 * preferred for use in arguments and as a key within objects.
 *
 * @category data
 */

import { useQuery, useMutation, useQueryClient } from "react-query";

import { api, ApiResponse } from "_/utils";
import { Uuid } from "_/types";

import { Org } from "_/data/orgs";
import { Role } from "_/data/rbac";

const SCOPE = "invitations";
const KEYS = {
  all: () => [SCOPE],
  org: (orgId: Uuid) => [SCOPE, "org", orgId],
};

/**
 * Invitation for new users to join an organization within Basis and optionally create an account if they do not yet have one.
 */
export type Invitation = {
  /**
   * Invitation ID.
   */
  id: Uuid;

  /**
   * Organization the user is being invited to.
   */
  organizationId: Uuid;

  /**
   * User role to be assigned upon invitation completion.
   */
  role: Role;

  /**
   * Email the invitation is sent to.
   */
  email: string;

  /**
   * User that initiated the invitation.
   */
  invitedBy: Uuid;

  /**
   * Flag indicating if the invitation has been approved.
   */
  approved: boolean;

  /**
   * Flag indicating if the invitation has been accepted.
   */
  accepted: boolean;

  /**
   * User that approved or denied the invitation.
   */
  reviewedBy: Uuid;

  /**
   * User that accepted or rejected the invitation.
   */
  receivedBy: string;

  /**
   * If set, the user that revoked the invitation
   */
  revokedBy: Uuid | null;

  /**
   * Date and time the invitation was created.
   */
  createdAt: string;

  /**
   * Date and time the invitation was last modified.
   *
   * This will be updated when the invitation is approved, denied, accepted, resent, or revoked.
   */
  updatedAt: string;

  /**
   * Expiration date and time for the invitation.
   */
  expiresAt: string;
};

export type InvitationWithDetail = Invitation & {
  organizationName: string;
  inviterName: string;
};

export function useInviteUser() {
  const queryClient = useQueryClient();

  async function requestInvite({ email, org }: { email: string; org: Org }) {
    const response = await api.post<ApiResponse<Invitation>>("/invitations", {
      email,
      orgId: org.id,
    });

    return response.data.data;
  }

  return useMutation({
    mutationFn: requestInvite,
    onSuccess: () => queryClient.invalidateQueries(KEYS.all()),
  });
}

export function useInviteMutations() {
  const queryClient = useQueryClient();

  async function resendInvite(params: {
    invitationId: Uuid;
  }): Promise<Invitation> {
    const response = await api.post<ApiResponse<Invitation>>(
      `/invitations/${params.invitationId}/resend`
    );
    return response.data.data;
  }

  async function revokeInvite(params: {
    invitationId: Uuid;
  }): Promise<Invitation> {
    const response = await api.delete<ApiResponse<Invitation>>(
      `/invitations/${params.invitationId}`
    );
    return response.data.data;
  }

  async function reviewInvite(params: {
    invitationId: Uuid;
    approval: boolean;
  }): Promise<Invitation> {
    const response = await api.post<ApiResponse<Invitation>>(
      `/invitations/${params.invitationId}/review`,
      {
        approval: params.approval,
      }
    );
    return response.data.data;
  }

  async function receiveInvite(params: {
    invitationId: Uuid;
    acceptance: boolean;
  }): Promise<Invitation> {
    const response = await api.post<ApiResponse<Invitation>>(
      `/invitations/${params.invitationId}/receive`,
      {
        acceptance: params.acceptance,
      }
    );
    return response.data.data;
  }

  const onSuccess = (data: Invitation) => {
    const updateData = (oldData: Invitation[] | undefined): Invitation[] => {
      if (!oldData) {
        return [data];
      }

      return oldData.map((item) => {
        return item.id === data.id ? data : item;
      });
    };

    queryClient.setQueryData(KEYS.all(), updateData);
    queryClient.setQueryData(KEYS.org(data.organizationId), updateData);
  };

  return {
    resendInvite: useMutation({ mutationFn: resendInvite }),
    revokeInvite: useMutation({ mutationFn: revokeInvite, onSuccess }),
    reviewInvite: useMutation({ mutationFn: reviewInvite, onSuccess }),
    receiveInvite: useMutation({ mutationFn: receiveInvite, onSuccess }),
  };
}

export function useInvites(orgId?: Uuid) {
  async function getOrgInvitations() {
    const response = await api.get<ApiResponse<InvitationWithDetail[]>>(
      `/organizations/${orgId}/invitations`
    );
    return response.data.data;
  }

  async function getAllInvitations() {
    const response =
      await api.get<ApiResponse<InvitationWithDetail[]>>(`/invitations`);
    return response.data.data;
  }

  const config = {
    queryKey: orgId ? KEYS.org(orgId) : KEYS.all(),
    queryFn: orgId ? getOrgInvitations : getAllInvitations,
  };

  return useQuery(config);
}
