import React, { Fragment, ReactElement, ReactNode } from "react";
import {
  CellContext,
  ColumnDef,
  InitialTableState,
  RowData,
  RowModel,
  SortDirection,
  Table as RTable,
  flexRender,
  getCoreRowModel as rtGetCoreRowModel,
  getSortedRowModel as rtGetSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { t } from "@lingui/macro";

import { Icon } from "_/components/icon";
import { TextLink } from "_/components/router";

import * as S from "./styled";

export type { ColumnDef, CellContext };

// Extending as recommended in the react-table docs:
// https://tanstack.com/table/v8/docs/api/core/column-def#meta
declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    customTd?: boolean;
  }
}

type TableProps<TData> = {
  columns: ColumnDef<TData>[];
  data: TData[];
  getCoreRowModel?: (table: RTable<TData>) => () => RowModel<TData>;
  getSortedRowModel?: (table: RTable<TData>) => () => RowModel<TData>;
  noDataContent?: () => ReactElement;
  initialState?: InitialTableState;
};

const DefaultNoData = (): ReactElement => {
  const label = t`common.no-data`;
  return <S.NoData>{label}</S.NoData>;
};

const SortIndicator = ({
  sort,
}: {
  sort: SortDirection | false;
}): ReactElement => {
  switch (sort) {
    case "asc":
      return <Icon variant="ExpandLess" />;
    case "desc":
      return <Icon variant="ExpandMore" />;
    default:
      // Use a default icon that is hidden to preserve consistent layout.
      return <Icon style={{ visibility: "hidden" }} variant="Circle" />;
  }
};

export function Table<TData>({
  columns,
  data,
  getCoreRowModel = rtGetCoreRowModel(),
  getSortedRowModel = rtGetSortedRowModel(),
  noDataContent = DefaultNoData,
  initialState,
}: TableProps<TData>): ReactElement {
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel,
    getSortedRowModel,
    initialState,
  });

  const headers = table.getHeaderGroups().map((hg) => {
    const headerCols = hg.headers.map((header) => {
      return (
        <S.Th
          key={`${header.index}_${header.id}`}
          onClick={header.column.getToggleSortingHandler()}
          colSpan={header.colSpan}
          role="columnheader"
        >
          <S.ThCell>
            {flexRender(header.column.columnDef.header, header.getContext())}
            <SortIndicator sort={header.column.getIsSorted()} />
          </S.ThCell>
        </S.Th>
      );
    });

    return (
      <S.Tr key={hg.id} role="row">
        {headerCols}
        <S.Th />
      </S.Tr>
    );
  });

  const rows = table.getRowModel().rows.map((row) => {
    const cells = row.getVisibleCells().map((cell) => {
      // Custom TDs must have `role="gridcell"`. If callers derive their <td>
      // from S.Td, then they'll get this ARIA role automatically.
      const Td = cell.column.columnDef?.meta?.customTd ? Fragment : S.Td;
      const content = flexRender(cell.column.columnDef.cell, cell.getContext());
      return <Td key={cell.id}>{content}</Td>;
    });

    return (
      <S.Tr key={row.id} role="row">
        {cells}
        <S.Td role="gridcell" />
      </S.Tr>
    );
  });

  // Visible column count required for styling rules set on the table since
  // we use display: grid for the layout.
  const columnCount = table.getLeafHeaders().length;

  const noContent = rows.length ? null : (
    <S.Tr role="row">
      <S.NoDataTd role="gridcell">{noDataContent()}</S.NoDataTd>
    </S.Tr>
  );

  return (
    // Don't remove this container element; it's required for Safari
    // to render grid cells with `height: 100%` correctly.
    <S.TableContainer>
      <S.Table $columnCount={columnCount} role="grid">
        <S.Thead>{headers}</S.Thead>
        <S.Tbody>
          {rows}
          {noContent}
        </S.Tbody>
      </S.Table>
    </S.TableContainer>
  );
}

/**
 * Render a table cell that contains a link.
 *
 * The column definition that uses this component must include `meta: {
 * customTd: true }` which will use the custom TD element contained in this
 * component instead of the default TD element used in regular cells.
 *
 * For cells that sometimes contain links and other times contain only static
 * text, then use the `<CustomCellStatic>` component to handle the static case.
 */
export const CustomCellLink = ({
  to,
  children,
}: {
  to: string;
  children: ReactNode;
}) => (
  <S.CustomTd>
    <TextLink variant="default" to={to}>
      <S.CustomTdContent>{children}</S.CustomTdContent>
    </TextLink>
  </S.CustomTd>
);

/**
 * Render static text for a table cell that may sometimes contain a link.
 * This component is used in coordination with `<CustomCellLink>`.
 *
 * The column definition that uses this component must include `meta: {
 * customTd: true }` which will use the custom TD element contained in this
 * component instead of the default TD element used in regular cells.
 */
export const CustomCellStatic = ({ children }: { children?: ReactNode }) => (
  <S.CustomTd>
    <S.CustomTdContent>{children ?? null}</S.CustomTdContent>
  </S.CustomTd>
);
