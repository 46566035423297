import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 360px;
`;

export const Form = styled.form`
  margin-top: 12px;

  button {
    margin-top: 24px;
  }
`;
