/**
 * User data fetching and manipulation methods.
 *
 * This module includes
 *
 * @category data
 */

import {
  useQueryClient,
  useQuery,
  useMutation,
  QueryClient,
} from "react-query";
import { equals } from "ramda";

import { api, ApiResponse } from "_/utils";
import { Uuid } from "_/types";

const SCOPE = "users";
const KEYS = {
  current: () => [SCOPE, "current"],
  individual: (userId: Uuid) => [SCOPE, "individual", userId],
  org: (orgId: Uuid) => [SCOPE, "org", orgId],
  all: () => [SCOPE, "all"],
};

/**
 * Main struct for users.
 */
export type User = {
  /**
   * User ID.
   */
  id: Uuid;

  /**
   * User's primary domain ID.
   */
  domainId: Uuid;
  /**
   * Users full name.
   */
  name: string;
  /**
   * Users email address.
   */
  email: string;
  /**
   * User phone number.
   *
   * This is an optional field but will be an empty string if not set.
   */
  phone: string;
  /**
   * User email verification state.
   */
  verified: boolean;
  /**
   * Users preferred language.
   *
   * This is stored as an ISO 639-1 language code excluding the locale - for example `en` rather
   * than `en-US`. See [here](https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes) for
   * valid values.
   */
  language: string;
  /**
   * User avatar blob ID.
   *
   * This field is not immediately useful to the client, but can be used as a
   * flag to indicate whether or not the user has set an avatar.
   */
  avatarId?: string;

  /**
   * Date the user was last authenticated.
   */
  lastAuthenticated: string;

  /**
   * True if the user is a super-admin, false otherwise.
   */
  superadmin: boolean;
};

/**
 * Struct for members of an organization.
 */
export type OrganizationUser = {
  /**
   * User within an organization.
   */
  user: User;

  /**
   * Flag to indicate whether the user has been approved as a member.
   */
  active: boolean;
};

export function useUserVerification() {
  async function verifyUser(params: {
    id: Uuid;
    token: string;
  }): Promise<void> {
    await api.put<ApiResponse<void>>("/users/verify", params);
  }

  return useMutation({ mutationFn: verifyUser });
}

export function useRequestPasswordReset() {
  async function requestPasswordReset(params: { email: string }) {
    return await api.post<ApiResponse<void>>("/auth/reset", params);
  }

  return useMutation({ mutationFn: requestPasswordReset });
}

/**
 * Mutation for user password reset flow.
 */
export function usePasswordReset() {
  async function passwordReset(params: {
    id: Uuid;
    token: string;
    password: string;
  }): Promise<void> {
    await api.post<ApiResponse<void>>("/auth/reset/password", params);
  }

  return useMutation({ mutationFn: passwordReset });
}

function updateUserInCache(queryClient: QueryClient, user: User) {
  const cached = queryClient.getQueryData<User[]>(KEYS.all());
  if (cached) {
    const index = cached.findIndex((u) => u.id === user.id);
    if (index !== -1) {
      if (!equals(cached[index], user)) {
        const updated = [...cached];
        updated[index] = user;
        queryClient.setQueryData(KEYS.all(), updated);
      }
    } else {
      // This user isn't in the cache, so invalidate the (obviously stale) cache.
      queryClient.invalidateQueries(KEYS.all());
    }
  }
}

type UserUpdatableFields = Omit<
  User,
  "domainId" | "id" | "verified" | "lastAuthenticated" | "superadmin"
>;

interface UserUpdateArgs {
  id: Uuid;
  update: UserUpdatableFields;
}

export function useUpdateUser() {
  const queryClient = useQueryClient();

  async function updateUser({ id, update }: UserUpdateArgs): Promise<User> {
    const response = await api.patch<ApiResponse<User>>(`/users/${id}`, update);
    return response.data.data;
  }

  return useMutation({
    mutationFn: updateUser,
    onSuccess: (data: User, _vars, _context) => {
      const currentUser = queryClient.getQueryData<User>(KEYS.current());

      // Update the currentUser record if updating the current user.
      if (currentUser?.id === data.id) {
        queryClient.setQueryData(KEYS.current(), data);
      }

      updateUserInCache(queryClient, data);
    },
  });
}

/**
 * Mutation to change the users password.
 */
export function useChangePassword() {
  async function changePassword(update: {
    old: string;
    new: string;
  }): Promise<void> {
    await api.patch<ApiResponse<void>>("/auth/password", update);
  }

  return useMutation({ mutationFn: changePassword });
}

// FIXME: Not quite complete yet!
export function useDeleteUser() {
  console.error("Not implemented!");

  return;

  async function deleteUser({ id }: { id: Uuid }): Promise<void> {
    await api.delete<ApiResponse<void>>(`/users/${id}`);
  }

  return useMutation({ mutationFn: deleteUser });
}

interface LoginArgs {
  email: string;
  password: string;
}

/**
 * Mutation hook to log in a user with standard auth using email
 * and password.
 */
export function useLoginUser() {
  const queryClient = useQueryClient();

  async function loginUser({ email, password }: LoginArgs): Promise<User> {
    const response = await api.post<ApiResponse<User>>("/auth", {
      email,
      password,
    });
    return response.data.data;
  }

  return useMutation({
    mutationFn: loginUser,
    onSuccess: (data, _vars, _context) => {
      // Force reloading all data after logging in.
      queryClient.invalidateQueries();

      const key = KEYS.current();
      queryClient.setQueryData(key, data);
    },
  });
}

/**
 * Query hook to log out user.
 */
export function useLogoutUser() {
  const queryClient = useQueryClient();

  async function logoutUser(): Promise<void> {
    await api.delete<ApiResponse<void>>("/auth");
  }

  return useMutation({
    mutationFn: logoutUser,
    onSuccess: (_data, _vars, _context) => {
      queryClient.clear();
    },
  });
}

interface UploadArgs {
  id: Uuid;
  file: File;
}

/**
 * Mutation hook to upload user avatar.
 */
export function useUploadAvatar() {
  const queryClient = useQueryClient();

  async function uploadAvatar({ id, file }: UploadArgs): Promise<void> {
    await api.put<ApiResponse<void>>(`/users/${id}/avatar`, file);
  }

  return useMutation({
    mutationFn: uploadAvatar,
    onSuccess: (_data, _vars) => {
      queryClient.invalidateQueries(KEYS.current());
      queryClient.invalidateQueries(KEYS.all());
    },
  });
}

/**
 * Mutation hook to remove the avatar from a user account by ID.
 */
export function useRemoveAvatar() {
  const queryClient = useQueryClient();

  async function removeAvatar({ id }: { id: Uuid }): Promise<void> {
    await api.delete<ApiResponse<void>>(`/users/${id}/avatar`);
  }

  return useMutation({
    mutationFn: removeAvatar,
    onSuccess: (_data, _vars) => {
      queryClient.invalidateQueries(KEYS.current());
      queryClient.invalidateQueries(KEYS.all());
    },
  });
}

async function getUsers() {
  const response = await api.get<ApiResponse<User[]>>("/users");
  return response.data.data;
}

async function currentUser(): Promise<User> {
  const response = await api.get<ApiResponse<User>>("/users/me");
  return response.data.data;
}

/**
 * Query hook to retrieve user by ID.
 */
export function useUser({ id }: { id: Uuid }) {
  return useQuery({
    queryFn: getUsers,
    queryKey: KEYS.all(),
    select: (users: User[]) => users.find((u) => u.id === id),
  });
}

/**
 * Query hook for initial retrieval of the currently signed in user. This hook
 * is used in components where the value of the current user may realistically be undefined,
 * so that these cases can be handled in isolation from the other uses where the user
 * should always be defined.
 */
export function useCheckUser() {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: KEYS.current(),
    queryFn: currentUser,
    onSuccess: (data) => {
      if (!data) {
        console.warn("No object returned in query for current user.");
        return;
      }

      updateUserInCache(queryClient, data);
    },
    retry: false,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    placeholderData: null,
  });
}

/**
 * Query hook to retrieve currently signed in user. This hook simply returns
 * the cached current user if it exists and throws an error if it doesn't, so
 * the return value is never undefined.
 */
export function useCurrentUser() {
  const queryClient = useQueryClient();

  const data = queryClient.getQueryData<User>(KEYS.current());

  if (!data) {
    throw new Error("User data is required but not available.");
  }

  return { data };
}

/**
 * Query hook to retrieve all users in the user's org.
 */
export function useUsers() {
  return useQuery({
    queryFn: getUsers,
    queryKey: KEYS.all(),
    placeholderData: [],
    refetchOnWindowFocus: false,
  });
}

/**
 * Get the members of a specific organization by ID.
 */
async function getUsersByOrg({
  orgId,
}: {
  orgId: Uuid;
}): Promise<OrganizationUser[]> {
  const response = await api.get<ApiResponse<OrganizationUser[]>>(
    `/organizations/${orgId}/users`
  );

  return response.data.data;
}

/**
 * Query hook for [[`getUsersByOrg`]].
 */
export function useUsersByOrg(orgId: Uuid) {
  return useQuery({
    queryKey: KEYS.org(orgId),
    queryFn: () => getUsersByOrg({ orgId }),
  });
}

/**
 * Check whether the provided email is an AON3D staff email address.
 * This will check for both production and test domains. Note that the `.test`
 * TLD is a special reserved domain which can never be registered, so it is
 * safe for use in test and development environments.
 */
export function isAonEmail(email: string) {
  return (
    email.toLowerCase().endsWith("@aon3d.com") ||
    email.toLowerCase().endsWith("@aon3d.test")
  );
}

// Returns `true` if the current user's email is an AON3D account.
export function useIsAonUser(): boolean {
  const { data: currentUser } = useCurrentUser();

  if (!currentUser) {
    return false;
  }

  const { email } = currentUser;

  return isAonEmail(email);
}
